import "../index.css";
import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { FaTwitter, FaDiscord, FaClipboard } from "react-icons/fa";
import * as utils from "../json/artist_artworks";
import { gsap } from "gsap";
import { hoursAgo, roundToOndeDecimal } from "../utils/calculation";

// COMPONENTS

function App() {
  const location = useLocation();
  let pathname;
  // Create a state
  const [selectedArtwork, setSelectedArtwork] = useState(0);
  const [count, setCount] = useState(0);
  const [buttonState, setButtonState] = useState("phantom");
  const [activeButtons, setActiveButtons] = useState([]);
  const [number, setNumber] = useState(100);
  const [daily, setDaily] = useState(0.02);
  const [monthly, setMonthly] = useState(0.66);
  const [yearly, setYearly] = useState(8.0);
  const [copied, setCopied] = useState(false);
  const [keyboard, setKeyboard] = useState(false);
  const [isPrivacyOverlayVisible, setIsPrivacyOverlayVisible] = useState(false);
  const [isTermsOverlayVisible, setIsTermsOverlayVisible] = useState(false);
  const videoRefs = [useRef(null), useRef(null), useRef(null)];
  const textToCopy = "Va1idkzkB6LEmVFmxWbWU8Ao9qehC62Tjmf68L3uYKj";
  const [artlistcopy, setArtlistcopy] = useState(utils.artist_artworks);
  const [state, setState] = useState({
    apy_estimate: 7.505,
    activated_stake: 911936.0842017371,
  });

  const fetchApiData = () => {
    fetch(
      `https://api.stakewiz.com/validator/Va1idkzkB6LEmVFmxWbWU8Ao9qehC62Tjmf68L3uYKj`
    )
      .then((response) => response.json())
      .then((data) => {
        // Store the result in local storage
        localStorage.setItem("data", JSON.stringify(data));
        localStorage.setItem("timestamp", Date.now());
        setState(data);
        reCalculateValues(100, roundToOndeDecimal(data.apy_estimate / 0.95));
      })
      .catch((error) => {
        // Handle any errors that occur during the request
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    const storedData = localStorage.getItem("data");
    const storedTimestamp = localStorage.getItem("timestamp");

    // Check if the data exists in local storage and if it's within the last 24 hours
    if (storedData && storedTimestamp) {
      const lastFetchedTime = Number(storedTimestamp);

      if (!hoursAgo(lastFetchedTime, 24)) {
        const localData = JSON.parse(storedData);
        setState(JSON.parse(storedData));
        reCalculateValues(
          100,
          roundToOndeDecimal(localData.apy_estimate / 0.95)
        );
        return;
        // Data is still valid, use it from local storage
      }
    }
    fetchApiData();
  }, []);

  let { apy_estimate, activated_stake } = state;
  activated_stake = activated_stake
    ? Math.trunc(Number(activated_stake)).toLocaleString()
    : 0;

  apy_estimate = apy_estimate ? roundToOndeDecimal(apy_estimate / 0.95) : 0;
  // ********* GREENSOCK ***************
  const el = useRef();
  const q = gsap.utils.selector(el);
  const tl = useRef();

  useEffect(() => {
    tl.current = gsap
      .timeline({ delay: 0.5 })
      .to(
        q(".Initial-Logo img"),
        { opacity: 1, duration: 0.7, y: 20, scale: 1.3 },
        0
      )
      .to(
        q(".Initial-Logo video"),
        { opacity: 1, duration: 0.7, y: 20, scale: 1.3 },
        0
      )
      .to(q(".Initial-Logo img"), { opacity: 0, duration: 0.5, y: 20 }, 2)
      .to(q(".Initial-Logo video"), { opacity: 0, duration: 0.5, y: 20 }, 2)
      .to(q(".Initial-Overlay"), { opacity: 0, duration: 1 }, "+=0.0")
      .to(q(".ArtworkBG"), { opacity: 1, duration: 2 }, "-=1.0")
      .to(q(".BG-overlay"), { opacity: 0.5, duration: 0.5 }, "+=0.5")
      .to(q(".Logo"), { opacity: 1, y: 10, duration: 0.5 }, "-=0.3")
      .to(q(".Left-Col h1"), { opacity: 1, y: 10, duration: 0.5 }, "-=0.3")
      .to(q(".Left-Col h2"), { opacity: 1, y: 10, duration: 0.5 }, "+=0.3")
      .to(
        q(".Buttons-Bar .Button"),
        { opacity: 1, stagger: 0.3, y: 10, duration: 0.5 },
        "-=0.6"
      )
      .to(
        q(".Stake-Highlight"),
        { opacity: 1, stagger: 0.3, y: 10, duration: 0.5 },
        "-=0.0"
      )
      .to(
        q(".Social a"),
        { opacity: 1, stagger: 0.3, y: 10, duration: 0.5 },
        "-=0.3"
      )
      .to(q(".ArtistInfo"), { opacity: 1, duration: 0.5 }, "-=0.3")
      .to(
        q(".Right-Col .FeatureButton"),
        {
          opacity: 1,
          duration: 1,
          y: 10,
          rotation: "2deg",
          ease: "power4.out",
        },
        "+=0.5"
      )
      .to(q(".Section1 .ArrowDown"), { opacity: 1, duration: 0.5 }, "+=1");
  }, [location]);

  // ********* PREPARE AND DISPLAY ARTWORK *********
  useEffect(() => {
    let temp = 0;
    for (let i = 0; i < utils.artist_artworks.length; i++) {
      console.log(utils.artist_artworks[i].title);
    }

    let random = Math.floor(Math.random() * utils.artist_artworks.length);
    let art = utils.artist_artworks[random];

    let bgart = {
      artist_name: art.artist,
      image_file: art.image_file,
      homepage_style: art.homepage_style,
      paletteBGColor: art.paletteBGColor,
      paletteFontColor: art.paletteFontColor,
      artist_link: art.artist_link,
    };

    setSelectedArtwork(bgart);
  }, []);

  // ********* ARROW KEY *********
  useEffect(() => {
    const handleKeyPress = (event) => {
      const shuffled = [...artlistcopy];
      if (!keyboard) {
        for (let i = shuffled.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
        }
        setArtlistcopy(shuffled);
        console.log(shuffled);
      }

      setKeyboard(true);
      if (event.key === "ArrowRight") {
        console.log(count);
        console.log(artlistcopy.length);
        if (count == artlistcopy.length - 1) {
          setCount(artlistcopy.length - 1);
        } else {
          setCount(count + 1);
        }
      }
      setKeyboard(true);
      if (event.key === "ArrowLeft") {
        if (count <= 0) {
          setCount(0);
        } else {
          setCount(count - 1);
        }
      }
    };

    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [count]);

  // ********* ARROW KEY UPDATES ARTWORK *********
  useEffect(() => {
    let art = artlistcopy[count];
    let bgart = {
      artist_name: art.artist,
      image_file: art.image_file,
      homepage_style: art.homepage_style,
      paletteBGColor: art.paletteBGColor,
      paletteFontColor: art.paletteFontColor,
      artist_link: art.artist_link,
    };
    if (keyboard) {
      setSelectedArtwork(bgart);
    }
  }, [count]);

  // ********* TUTORIAL CLICK *********
  const handleClick = (buttonName) => {
    setButtonState(buttonName[0]);
    console.log(buttonName[1]);
    if (videoRefs[buttonName[1]].current) {
      videoRefs[buttonName[1]].current.currentTime = 0;
      videoRefs[buttonName[1]].current.play();
    }
  };

  // ********* TUTORIAL CLICK *********
  const handleFAQClick = (buttonId) => {
    if (activeButtons.includes(buttonId)) {
      setActiveButtons(activeButtons.filter((id) => id !== buttonId));
    } else {
      setActiveButtons([...activeButtons, buttonId]);
    }
  };

  const isButtonActive = (buttonId) => {
    return activeButtons.includes(buttonId);
  };

  // ********* SCROLL FUNCTIONALITY *********
  const scrollToSection = (sectionClass) => {
    const section = document.querySelector(sectionClass);
    if (section) {
      const offset = section.offsetTop + 50; // Calculate the offset with an additional 50px
      window.scrollTo({ top: offset, behavior: "smooth" });
    }
  };

  // ********* CALCULATOR  *********

  const handleChange = (event) => {
    const inputValue = event.target.value;
    setNumber(inputValue);
    reCalculateValues(inputValue, apy_estimate);
  };

  const reCalculateValues = (inputValue, apyEstimate) => {
    // Calculate percentages
    // const parsedNumber = parseFloat(number);
    const dailyPercentage = (inputValue * (apyEstimate / 100)) / 365;
    const monthlyPercentage = (inputValue * (apyEstimate / 100)) / 12;
    const yearlyPercentage = inputValue * (apyEstimate / 100);

    setDaily(dailyPercentage.toFixed(2));
    setMonthly(monthlyPercentage.toFixed(2));
    setYearly(yearlyPercentage.toFixed(2));
  };

  // ********* CLIPBOARD   *********

  const handleCopyClick = () => {
    navigator.clipboard.writeText(textToCopy); // Copy text to clipboard
    setCopied(true); // Update state to indicate the text was copied

    // Reset the copied state after a certain period (optional)
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  // ********* CLIPBOARD   *********

  const privacyButtonClick = () => {
    setIsPrivacyOverlayVisible(true);
  };

  const privacyCloseClick = () => {
    setIsPrivacyOverlayVisible(false);
  };

  const termsButtonClick = () => {
    setIsTermsOverlayVisible(true);
  };

  const termsCloseClick = () => {
    setIsTermsOverlayVisible(false);
  };

  return (
    <div className="App" ref={el}>
      {/* ********* SECTION 1 *********  */}

      <div
        className={`Section1 Style-${selectedArtwork.homepage_style} Font-${selectedArtwork.paletteFontColor}`}
      >
        <div className="Logo">
          <img src="img/logo/validator-logo-blackyellowname.svg" />
          <img
            className="Blurred"
            src="img/logo/validator-logo-blackyellowname.svg"
          />
        </div>
        <div className="Social">
          <a href="https://twitter.com/validator_com">
            <FaTwitter />
          </a>
          <a href="https://discord.gg/eCJSb8PspY">
            <FaDiscord />
          </a>
        </div>

        <div className="Columns">
          <div className="Left-Col">
            <h1>
              <span>
                Secure rewards for your <span class="Underline">stake</span>.
              </span>
            </h1>
            <h2>
              <span>
                One of the <strong>largest</strong> community validators on the
                Solana network.{" "}
              </span>
            </h2>
            <div className="Buttons-Bar">
              <div
                className="Button"
                onClick={() => scrollToSection(".SectionCalculator")}
              >
                Rewards
              </div>
              <div
                className="Button"
                onClick={() => scrollToSection(".Section2")}
              >
                Quick Tutorial
              </div>
              <div
                className="Button"
                onClick={() => scrollToSection(".Section3")}
              >
                Stats
              </div>
              <div
                className="Button"
                onClick={() => scrollToSection(".Section4")}
              >
                FAQ
              </div>
            </div>
            <div className="Stake-Highlight">Earning {apy_estimate}% APY</div>
          </div>

          <div className="Right-Col">
            <div
              className="FeatureButton"
              onClick={() => scrollToSection(".Section2")}
            >
              30 Second Guide
            </div>
          </div>
        </div>

        <div className="ArrowDown">↓</div>

        <div className="ArtistInfo">
          <div className="InfoLine">We support Solana artists too.</div>
          <div className="InfoLine">
            Background:{" "}
            <a href={selectedArtwork.artist_link} target="_blank">
              {selectedArtwork.artist_name}{" "}
            </a>
          </div>
        </div>

        <div className={`Artwork BG-${selectedArtwork.paletteBGColor}`}>
          <div className="Initial-Logo">
            <img
              className="Initial-Gif"
              src="https://validator.b-cdn.net/validator-assets/video/validator-intro3.gif"
            />
            <img className="Initial-Text" src="img/logo/logo-text.png" />
          </div>
          <div className="BG-overlay"></div>
          <img
            className="ArtworkBG"
            src={`https://validator.b-cdn.net/validator-assets/img/artist-images/${selectedArtwork.image_file}`}
          />
          <div className="Initial-Overlay"></div>
        </div>
      </div>

      {/* ********* SECTION 2 *********  */}

      <div className="Section SectionCalculator">
        <div className="Header">
          <div className="Column">
            <h1>Daily rewards for doing very little.</h1>
            <h2>Earn staking rewards with your feet up.</h2>
          </div>
          <div className="Column">
            <div className="FeatureImage">
              <img src="img/site-emojis/flex2-800.png" />
            </div>
          </div>
        </div>

        <div className="Calculator">
          <div className="Title">Calculate Your Rewards</div>
          <div className="InnerCalculator">
            <div className="Calc-Column">
              <div className="UserAmount">
                <input type="number" value={number} onChange={handleChange} />
                <div className="UserControls">
                  <div className="UserUp"></div>
                  <div className="UserDown"></div>
                </div>
              </div>
            </div>

            <div className="Calc-Column">
              {/* <div className="Title">Your Rewards</div> */}
              <div className="Rewards">
                <div className="Row">
                  <div className="RowPeriod">
                    Daily: <span>{daily} SOL</span>{" "}
                  </div>
                </div>
                <div className="Row">
                  <div className="RowPeriod">
                    Monthly: <span>{monthly} SOL</span>{" "}
                  </div>
                </div>
                <div className="Row">
                  <div className="RowPeriod">
                    Yearly: <span>{yearly} SOL</span>{" "}
                  </div>
                </div>
              </div>
            </div>

            <div className="Info">
              <div className="Line">
                Based on the current ~ {apy_estimate}% APY | validator.com fee
                is 5% of rewards
              </div>
              {/* <div className="Line">Fee is 5% of rewards</div> */}
            </div>

            <div className="Address">
              <div className="AddressTitle">Address</div>
              <div className="AddressLine">
                Va1idkzkB6LEmVFmxWbWU8Ao9qehC62Tjmf68L3uYKj
                <span
                  onClick={handleCopyClick}
                  style={{ color: copied ? "yellow" : "white" }}
                >
                  <FaClipboard />
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="ArrowDown ArrowDown2">↓</div>
        <div className="BGs">
          <div className="BGOverlay-Black"></div>
          <div className="BGOverlay-Image">
            <img src="img/site-design-elements/gradient.jpg" />{" "}
          </div>
        </div>
      </div>

      {/* ********* SECTION 2 *********  */}

      <div className="Section Section2">
        <div className="Header">
          <div className="Column">
            <h1>Stake in 30 seconds.</h1>
            <h2>Your coins. Your custody. Your rewards.</h2>
          </div>
          <div className="Column">
            <div className="FeatureImage">
              <img src="img/site-emojis/lock-800.png" />
            </div>
          </div>
        </div>

        <div className="Tutorial">
          <div className="Heading">
            Select Your Wallet <span>▼</span>
          </div>
          <div className="Menu">
            <div
              className={buttonState === "phantom" ? "Active Button" : "Button"}
              onClick={() => handleClick(["phantom", 0])}
            >
              Phantom
            </div>
            <div
              className={
                buttonState === "solflare" ? "Active Button" : "Button"
              }
              onClick={() => handleClick(["solflare", 1])}
            >
              Solflare
            </div>
            <div
              className={buttonState === "ledger" ? "Active Button" : "Button"}
              onClick={() => handleClick(["ledger", 2])}
            >
              Ledger
            </div>
          </div>

          <div className="Steps">
            {/* *************** PHANTOM ****************** */}
            <div className={buttonState === "phantom" ? "Phantom" : "Hidden"}>
              <div className="Video">
                <video muted autoPlay loop playsInline ref={videoRefs[0]}>
                  <source
                    src="https://validator.b-cdn.net/validator-assets/video/phantom_tutorial.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>

              <div className="Title">Step By Step</div>

              <div className="Step Tall">
                <div className="Image">
                  {" "}
                  <img src="img/staking-images/phantom/1-phantom.png" />{" "}
                </div>
                <div className="Description">
                  <span> 1 </span> Select Solana
                </div>
              </div>

              <div className="Step Tall">
                <div className="Image">
                  {" "}
                  <img src="img/staking-images/phantom/2-phantom.png" />{" "}
                </div>
                <div className="Description">
                  <span> 2 </span> Deposit (you need SOL in your wallet)
                </div>
              </div>

              <div className="Step Tall">
                <div className="Image">
                  {" "}
                  <img src="img/staking-images/phantom/3-phantom.png" />{" "}
                </div>
                <div className="Description">
                  <span> 3 </span> Copy the address to deposit Solana{" "}
                </div>
              </div>

              <div className="Step Tall">
                <div className="Image">
                  {" "}
                  <img src="img/staking-images/phantom/4-phantom.png" />{" "}
                </div>
                <div className="Description">
                  <span> 4 </span> Once Solana is deposited, Select Solana
                </div>
              </div>

              <div className="Step Tall">
                <div className="Image">
                  {" "}
                  <img src="img/staking-images/phantom/5-phantom.png" />{" "}
                </div>
                <div className="Description">
                  <span> 5 </span> Select “Start earning SOL”
                </div>
              </div>

              <div className="Step Tall">
                <div className="Image">
                  {" "}
                  <img src="img/staking-images/phantom/6-phantom.png" />{" "}
                </div>
                <div className="Description">
                  <span> 6 </span> Select “Validator.com”
                </div>
              </div>

              <div className="Step Tall">
                <div className="Image">
                  {" "}
                  <img src="img/staking-images/phantom/7-phantom.png" />{" "}
                </div>
                <div className="Description">
                  <span> 7 </span> Enter the SOL to Stake, and select Stake.
                  Leave at least 0.05 SOL for transaction costs.
                </div>
              </div>

              <div className="Step Tall">
                <div className="Image">
                  {" "}
                  <img src="img/staking-images/phantom/8-phantom.png" />{" "}
                </div>
                <div className="Description">
                  <span> 8 </span> Congratulations your SOL is staked{" "}
                </div>
              </div>
            </div>

            {/* *************** SOLFLARE ****************** */}
            <div className={buttonState === "solflare" ? "Solflare" : "Hidden"}>
              <div className="Video">
                <video muted autoPlay loop playsInline ref={videoRefs[1]}>
                  <source
                    src="https://validator.b-cdn.net/validator-assets/video/solflare_tutorial.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>

              <div className="Title">Step By Step</div>

              <div className="Step Tall">
                <div className="Image">
                  {" "}
                  <img src="img/staking-images/solflare/1-solflare.png" />{" "}
                </div>
                <div className="Description">
                  <span> 1 </span> Select “Receive”
                </div>
              </div>

              <div className="Step Tall">
                <div className="Image">
                  {" "}
                  <img src="img/staking-images/solflare/2-solflare.png" />{" "}
                </div>
                <div className="Description">
                  <span> 2 </span> Copy the address to deposit Solana
                </div>
              </div>

              <div className="Step Tall">
                <div className="Image">
                  {" "}
                  <img src="img/staking-images/solflare/3-solflare.png" />{" "}
                </div>
                <div className="Description">
                  <span> 3 </span> Select “Full-Screen View”
                </div>
              </div>

              <div className="Step Wide">
                <div className="Image">
                  {" "}
                  <img src="img/staking-images/solflare/4-solflare.png" />{" "}
                </div>
                <div className="Description">
                  <span> 4 </span> Select “Staking”
                </div>
              </div>

              <div className="Step Wide">
                <div className="Image">
                  {" "}
                  <img src="img/staking-images/solflare/5-solflare.png" />{" "}
                </div>
                <div className="Description">
                  <span> 5 </span> Select “Start Staking”
                </div>
              </div>

              <div className="Step Wide">
                <div className="Image">
                  {" "}
                  <img src="img/staking-images/solflare/6-solflare.png" />{" "}
                </div>
                <div className="Description">
                  <span> 6 </span> Select “Native SOL Staking”{" "}
                </div>
              </div>

              <div className="Step Wide">
                <div className="Image">
                  {" "}
                  <img src="img/staking-images/solflare/7-solflare.png" />{" "}
                </div>
                <div className="Description">
                  <span> 7 </span> Enter the SOL to Stake, and select Stake,
                  Select “Validator.com” & Select Stake
                </div>
              </div>

              <div className="Step Wide">
                <div className="Image">
                  {" "}
                  <img src="img/staking-images/solflare/8-solflare.png" />{" "}
                </div>
                <div className="Description">
                  <span> 8 </span> Confirm your transaction by selecting
                  “Confirm”
                </div>
              </div>
            </div>

            {/* *************** LEDGER ****************** */}

            <div className={buttonState === "ledger" ? "Ledger" : "Hidden"}>
              <div className="Video">
                <video
                  className="LedgerVideo"
                  muted
                  autoPlay
                  loop
                  playsInline
                  ref={videoRefs[2]}
                >
                  <source
                    src="https://validator.b-cdn.net/validator-assets/video/ledger_tutorial.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>

              <div className="Title">Step By Step</div>

              <div className="Step Wide">
                <div className="Image">
                  {" "}
                  <img src="img/staking-images/ledger/1-ledger.png" />{" "}
                </div>
                <div className="Description">
                  <span> 1 </span> Select “Accounts” & Select “Solana”{" "}
                </div>
              </div>

              <div className="Step Wide">
                <div className="Image">
                  {" "}
                  <img src="img/staking-images/ledger/2-ledger.png" />{" "}
                </div>
                <div className="Description">
                  <span> 2 </span> Select “Receive”
                </div>
              </div>

              <div className="Step Wide">
                <div className="Image">
                  {" "}
                  <img src="img/staking-images/ledger/3-ledger.png" />{" "}
                </div>
                <div className="Description">
                  <span> 3 </span> Copy the address to deposit Solana & verify
                  that the address matches the one on your Ledger
                </div>
              </div>

              <div className="Step Wide">
                <div className="Image">
                  {" "}
                  <img src="img/staking-images/ledger/4-ledger.png" />{" "}
                </div>
                <div className="Description">
                  <span> 4 </span> Select “Stake”
                </div>
              </div>

              <div className="Step Wide">
                <div className="Image">
                  {" "}
                  <img src="img/staking-images/ledger/5-ledger.png" />{" "}
                </div>
                <div className="Description">
                  <span> 5 </span> Select “Continue”
                </div>
              </div>

              <div className="Step Wide">
                <div className="Image">
                  {" "}
                  <img src="img/staking-images/ledger/6-ledger.png" />{" "}
                </div>
                <div className="Description">
                  <span> 6 </span> Select “Show All”{" "}
                </div>
              </div>

              <div className="Step Wide">
                <div className="Image">
                  {" "}
                  <img src="img/staking-images/ledger/7-ledger.png" />{" "}
                </div>
                <div className="Description">
                  <span> 7 </span> Type & Select “Validator.com”
                </div>
              </div>

              <div className="Step Wide">
                <div className="Image">
                  {" "}
                  <img src="img/staking-images/ledger/8-ledger.png" />{" "}
                </div>
                <div className="Description">
                  <span> 8 </span> Enter the SOL to Stake & select “Continue”
                </div>
              </div>

              <div className="Step Wide">
                <div className="Image">
                  {" "}
                  <img src="img/staking-images/ledger/9-ledger.png" />{" "}
                </div>
                <div className="Description">
                  <span> 9 </span> Confirm the transaction on your Ledger{" "}
                </div>
              </div>

              <div className="Step Wide">
                <div className="Image">
                  {" "}
                  <img src="img/staking-images/ledger/10-ledger.png" />{" "}
                </div>
                <div className="Description">
                  <span> 10 </span> Congrats your SOL is staked
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="Quote">
          <div className="InvertedComma">“</div>
          <div className="Text">
            Staking is the <strong>easiest and safest</strong> method for
            someone to earn passive income in the crypto space.
          </div>
        </div>

        <div className="ArrowDown ArrowDown2">↓</div>
        <div className="BGs">
          <div className="BGOverlay-Black"></div>
          <div className="BGOverlay-Image">
            <img src="img/site-design-elements/gradient.jpg" />{" "}
          </div>
        </div>
      </div>

      {/* ********* SECTION 4 *********  */}

      <div className="Section Section3">
        <div className="Header">
          <div className="Column">
            <h1>Community centered node.</h1>
            <h2>
              Validator.com is one of the most trusted nodes and holds one of
              the largest stakes in the Solana network.
            </h2>
          </div>
          <div className="Column">
            <div className="FeatureImage">
              <img src="img/site-emojis//handshake-800.png" />
            </div>
          </div>
        </div>

        <div className="Subheader">About Us</div>

        <div className="Facts">
          <div className="Fact">
            <div className="Bubble">{activated_stake} SOL Staked</div>
            <div className="Description">
              {activated_stake} SOL staked on our validator, and on the way to
              1.69 million.{" "}
            </div>
          </div>

          <div className="Fact">
            <div className="Bubble">Est. 2022</div>
            <div className="Description">
              Trusted and experienced team in the ecosystem.
            </div>
          </div>

          <div className="Fact">
            <div className="Bubble">Earning {apy_estimate}% APY</div>
            <div className="Description">
              Your staked SOL earns between 7 - 9% APY (
              <em>Annual Percentage Yield</em>), and compounds the longer it
              stays staked.
            </div>
          </div>

          <div className="Fact">
            <div className="Bubble">5% Commission</div>
            <div className="Description">
              Our commission is a low and competitive fee of 5% of your staking
              rewards.
            </div>
          </div>
        </div>

        <div className="Team">
          <div className="BreakGraphic">...</div>
          <div className="TeamBlock">
            <div className="Heading">
              <div className="HeadingBox">The Team</div>
            </div>
            <div className="Description">
              Validator.com is a small team of passionate individuals that plays
              to their strengths. Like crypto itself, staking is for the masses.
              Our mission is to educate everyone, from everyday individuals to
              institutions and Web 2.0 and 3.0 companies venturing into crypto.
            </div>
          </div>
          <div className="TeamBlock">
            <div className="Heading">
              <div className="HeadingBox">Mission</div>
            </div>
            <div className="Description">
              While there is a technical side to validators, there is also an
              educational one. We give support, create tutorials, and keep you
              up-to-date on the Solana ecosystem.
            </div>
          </div>
          <div className="BreakGraphic">...</div>
        </div>

        <div className="Quote">
          <div className="InvertedComma">“</div>
          <div className="Text">
            Our goal is simple: <strong>maximize returns</strong> for our
            stakers with consistently <strong>low fees</strong> & high validator
            efficiency.
          </div>
        </div>

        <div className="ArrowDown ArrowDown2">↓</div>
        <div className="BGs">
          <div className="BGOverlay-Black"></div>
          <div className="BGOverlay-Image">
            <img src="img/site-design-elements/gradient.jpg" />{" "}
          </div>
        </div>
      </div>

      {/* ********* SECTION 3 *********  */}

      <div className="Section Section4">
        <div className="Header">
          <div className="Column">
            <h1>Education at the heart of what we do.</h1>
            <h2>
              Like crypto itself, staking is for the masses. Our mission is to
              educate everyone, from everyday individuals to institutions and
              Web 2.0 and 3.0 companies venturing into crypto.
            </h2>
          </div>
          <div className="Column">
            <div className="FeatureImage">
              <img src="img/site-emojis/cap-800.png" />
            </div>
          </div>
        </div>

        <div className="Subheader">FAQ</div>

        <div className="FAQ">
          <div className="Column">
            <div className="Category Category1">
              <div className="Heading">
                General <span>▼</span>
              </div>
              <div
                className={`Button ${isButtonActive(1) ? "OpenButton" : ""}`}
                onClick={() => handleFAQClick(1)}
              >
                <div className="Title">What is staking?</div>
                <div className="Answer">
                  <p>
                    Staking is where you assign your "voting rights" to a unique
                    Solana computer, called a validator, and earn a return of
                    about 6.5-8% per year, as of June 2023. Voting rights are
                    simply the ability to vote on a transaction, and each Solana
                    token has these rights. The validator uses them to vote if a
                    transaction was a good or bad transaction.{" "}
                  </p>
                  <p>
                    Solana is a "Proof of Stake" blockchain and allows for
                    1,000s of transactions per second. Often over 4,500. For
                    comparison, Ethereum can currently process about 15
                    transactions per second.
                  </p>
                  <p>
                    A validator is a node in the Solana network that votes on
                    which transactions to include in the blockchain. This is
                    similar to a miner in POW (proof of work) based chains like
                    Bitcoin & Ethereum, but since Solana is POS (Proof of
                    Stake), it depends on delegators and their Stake, instead of
                    hash power.
                  </p>
                  <p>
                    Staking is therefore delegating your voting rights to a
                    particular node to help keep the network secure and improve
                    decentralization. Solana validators can not be operated from
                    home, they require significant computing power and internet
                    bandwidth.
                  </p>
                </div>
              </div>
              <div
                className={`Button ${isButtonActive(2) ? "OpenButton" : ""}`}
                onClick={() => handleFAQClick(2)}
              >
                <div className="Title">Does the earned SOL compound?</div>
                <div className="Answer">
                  <p>Yes. It compounds automatically with every epoch.</p>
                </div>
              </div>
              <div
                className={`Button ${isButtonActive(3) ? "OpenButton" : ""}`}
                onClick={() => handleFAQClick(3)}
              >
                <div className="Title">Is it safe to Stake my SOL?</div>
                <div className="Answer">
                  <p>
                    <strong>Yes.</strong>
                  </p>
                  <p>
                    The Solana software has been audited (where computer
                    engineers check the program code) by Kudelski, a leading
                    cybersecurity firm. Solana Labs also has bug bounties, so
                    anything a developer finds, they can submit and receive a
                    financial reward.
                  </p>
                  <p>
                    You can always withdraw your Stake from the validator, and a
                    validator can't take your SOL. Our validator is hosted
                    professionally with enterprise-level computing servers. Even
                    if the power went down and the server turned off, you can
                    still withdraw your SOL. We never have your SOL, only the
                    "voting rights" that you can withdraw at any stage.
                  </p>
                </div>
              </div>
              <div
                className={`Button ${isButtonActive(4) ? "OpenButton" : ""}`}
                onClick={() => handleFAQClick(4)}
              >
                <div className="Title">
                  Why should I stake with Validator.com?
                </div>
                <div className="Answer">
                  <p>
                    Validator.com has massive goals to push the Solana ecosystem
                    forward.{" "}
                  </p>
                  <p>
                    Our team is bent on education for everyone, and we welcome
                    anyone to stake with us. You may be new to Solana, and have
                    1 SOL in your wallet, or a company with 250,000 SOL, we are
                    the choice for you.
                  </p>
                  <p>
                    Our upcoming “alpha” blog keeps you in the know. You have a
                    busy life. You want to know how to stake, why Solana, and
                    ecosystem updates in a concise form.
                  </p>
                  <p>
                    You would earn 6.5 - 8% a year on your SOL, and commission
                    is a low 5% of your staking rewards.{" "}
                  </p>
                </div>
              </div>
            </div>

            <div className="Category Category2">
              <div className="Heading">
                Fees and Rewards <span>▼</span>
              </div>

              <div
                className={`Button ${isButtonActive(5) ? "OpenButton" : ""}`}
                onClick={() => handleFAQClick(5)}
              >
                <div className="Title">How long is my SOL locked up for?</div>
                <div className="Answer">
                  <p>
                    Your staked SOL goes through a few phases. With Solana, the
                    minimum delegation time is one epoch. An epoch is a period
                    in time in computing, and in Solana, it is about 2.5 days.
                    To see the progress on the epoch you can visit Solana Beach
                    and it shows you the epoch number, ETA until end, and the
                    progress as a percentage.
                  </p>
                  <p>
                    The lock up on Solana is for just 1 epoch. Some other
                    blockchains, like in Cosmos, have 20+ day lockups.
                  </p>
                  <p>
                    Activating: When you delegate SOL, eg 1 SOL, it will show as
                    “activating” until the epoch changes to the next one. During
                    activating, no SOL earns rewards.
                  </p>
                </div>
              </div>

              <div
                className={`Button ${isButtonActive(15) ? "OpenButton" : ""}`}
                onClick={() => handleFAQClick(15)}
              >
                <div className="Title">How often do I receive rewards?</div>
                <div className="Answer">
                  <p>
                    When you have been staked for 1 epoch, you will be credited
                    with rewards at the end of the epoch.
                  </p>
                </div>
              </div>

              <div
                className={`Button ${isButtonActive(6) ? "OpenButton" : ""}`}
                onClick={() => handleFAQClick(6)}
              >
                <div className="Title">What are Validator.com's fees?</div>
                <div className="Answer">
                  <p>
                    Our Validator charges 5% commission from any{" "}
                    <strong>new SOL</strong> earned from staking.
                  </p>
                </div>
              </div>

              <div
                className={`Button ${isButtonActive(7) ? "OpenButton" : ""}`}
                onClick={() => handleFAQClick(7)}
              >
                <div className="Title">Where can I see my rewards?</div>
                <div className="Answer">
                  <p>
                    Solana staking is compounded, automatically, with no option
                    to withdraw the rewards. So if you staked 1000 SOL, it would
                    grow at about 1.1 SOL per week. At the end of the year, you
                    would have around 1060 SOL. Phantom only shows you your
                    rewards for the most recent epoch in Phantom.
                  </p>
                  <p>
                    A more comprehensive solution is Solstake. After each epoch,
                    it will show you each reward, and your staked SOL balance
                    will grow epoch by epoch.
                  </p>
                </div>
              </div>

              <div
                className={`Button ${isButtonActive(8) ? "OpenButton" : ""}`}
                onClick={() => handleFAQClick(8)}
              >
                <div className="Title">How much SOL should I delegate?</div>
                <div className="Answer">
                  <p>
                    As much, or as little, as you like. It is also a good idea
                    to do it in multiple batches. eg you might have 100 SOL to
                    delegate. Do 5 x 20 SOL delegations. That way, should you
                    ever need some SOL, you can unstake 1x 20 SOL delegation,
                    and earn rewards on the 80 SOL still.
                  </p>
                  <p>If you have 0.5 SOL, you can stake that too.</p>
                </div>
              </div>

              <div
                className={`Button ${isButtonActive(9) ? "OpenButton" : ""}`}
                onClick={() => handleFAQClick(9)}
              >
                <div className="Title">How do I Unstake?</div>
                <div className="Answer">
                  <p>
                    Unstaking is easy, and is two-step process which includes:
                    Unstaking + withdrawing. You will need some SOL for the
                    transaction cost (called gas). 0.01 SOL is enough, and if
                    using Phantom wallet, follow this:{" "}
                  </p>

                  <p>
                    <strong>Unstaking</strong>
                  </p>
                  <ul>
                    <li>Click on your Solana token balance in your wallet.</li>
                    <li>Click the "Your Stake" row.</li>
                    <li>
                      Choose the Validator.com staking account you wish to
                      unstake.
                    </li>
                    <li>
                      Click the purple "Unstake" button in the bottom right.
                    </li>
                  </ul>
                  <p>
                    This will deactivate the Stake, and when the epoch ends (you
                    can check the epoch length at Solana Beach or Solstake, you
                    can withdraw).{" "}
                  </p>

                  <p>
                    <strong>Withdrawing:</strong>
                  </p>
                  <p>
                    If your Validator.com staking account status is "Inactive",
                    you can withdraw your SOL back into your wallet.
                  </p>
                  <ul>
                    <li>Click on your Solana token balance in your wallet.</li>
                    <li>
                      Choose the Validator.com staking account you wish to
                      withdraw from.
                    </li>
                    <li>Click the 3 dots "..." in the top right.</li>
                    <li>Click "Withdraw SOL".</li>
                  </ul>
                </div>
              </div>

              <div
                className={`Button ${isButtonActive(10) ? "OpenButton" : ""}`}
                onClick={() => handleFAQClick(10)}
              >
                <div className="Title">What does rent reserve mean?</div>
                <div className="Answer">
                  <p>
                    Rent is a standard "deposit" that all delegators pay each
                    time a new stake is created. It is part of the Solana
                    software, and when you eventually unstake, you get it back.
                    It’s a very small amount of SOL. Just think of it as a
                    refundable deposit.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="Column Category3">
            <div className="Category">
              <div className="Heading">
                Security <span>▼</span>
              </div>

              <div
                className={`Button ${isButtonActive(11) ? "OpenButton" : ""}`}
                onClick={() => handleFAQClick(11)}
              >
                <div className="Title">
                  What on-site security does Validator.com use?
                </div>
                <div className="Answer">
                  <p>
                    We use Latitude.sh as our Validator partner and our
                    Validator hardware is housed with LDEX.
                  </p>
                  <p>
                    They take security seriously. Security guards, perimeter
                    fences, video surveillance, 24/7/365 secured entry, facial
                    recognition biometric systems, etc.
                  </p>
                  <p>Wish to learn more?</p>
                  <p>
                    View Ldexgroups’s site{" "}
                    <a href="https://www.ldexgroup.co.uk/uk-data-centres/london-datacentre-ldex1/">
                      Terms and Conditions
                    </a>{" "}
                    & <a href="https://www.latitude.sh/">Privacy Policy</a>.
                  </p>
                </div>
              </div>

              <div
                className={`Button ${isButtonActive(12) ? "OpenButton" : ""}`}
                onClick={() => handleFAQClick(12)}
              >
                <div className="Title">Can slashing take my SOL?</div>
                <div className="Answer">
                  <p>
                    You may have heard of slashing, which is used with some
                    other Proof of Stake blockchains.
                  </p>
                  <p>
                    When a validator is malicious (approving fake transactions
                    and double-spending), their Stake can be reduced, which is
                    bad for investors and encourages the validator to be honest.
                    Slashing is not live on Solana, it's likely years away, and
                    good validators would not be affected, as they don't
                    misbehave. We are a great validator, and we have our own SOL
                    staked, so we play by the rules, and protect the network.
                  </p>
                </div>
              </div>
            </div>

            <div className="Category">
              <div className="Heading">
                Tax + Help <span>▼</span>
              </div>

              <div
                className={`Button ${isButtonActive(13) ? "OpenButton" : ""}`}
                onClick={() => handleFAQClick(13)}
              >
                <div className="Title">
                  How can I download a report of my earnings?
                </div>
                <div className="Answer">
                  <p>
                    Whether for you or the taxman, you can get a report from
                    Solana Beach, or you can download all the transactions from
                    your wallet from stake.tax.
                  </p>
                  <p>
                    We recommend that you stake from a wallet address that only
                    does staking. This makes it easier for accounting purposes.
                    Your NFTs, DeFi, gaming etc. could be controlled from other
                    wallets.
                  </p>
                </div>
              </div>

              <div
                className={`Button ${isButtonActive(14) ? "OpenButton" : ""}`}
                onClick={() => handleFAQClick(14)}
              >
                <div className="Title">Where do I go for extra help?</div>
                <div className="Answer">
                  <p>
                    You can always reach out on Twitter or Discord. These links
                    are at the bottom of our website. We can’t offer this to
                    every potential staker, but if you have a sizeable SOL
                    portfolio, or are looking to get that, we can organize a
                    video chat with our General Manager, to talk you through the
                    additional security aspects, such as using a Ledger hardware
                    wallet, and answer any questions.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="Quote">
          <div className="InvertedComma">“</div>
          <div className="Text">
            Staking literally strengthens the Solana network, so it's a{" "}
            <strong> win-win incentive </strong> for us to educate and onboard
            new users for the ecosystem. It's the{" "}
            <strong>lowest risk play </strong>for all of us - both new and old
            users.{" "}
          </div>
        </div>

        {/* <div className="ArrowDown ArrowDown2">↓</div> */}
        <div className="BGs">
          <div className="BGOverlay-Black"></div>
          <div className="BGOverlay-Image">
            <img src="img/site-design-elements/gradient.jpg" />{" "}
          </div>
        </div>
      </div>

      {/* ********* SECTION 4 *********  */}

      <div className="FooterOuter">
        <div className="Footer">
          <div className="Logo">
            <img src="video/validator-footer3.gif" />
          </div>
          <div className="Text">
            {" "}
            <img src="img/logo/logo-text.png" />{" "}
          </div>
          <div className="Links">
            <div
              className="Link"
              onClick={() => scrollToSection(".SectionCalculator")}
            >
              Rewards
            </div>
            <div className="Link" onClick={() => scrollToSection(".Section2")}>
              Tutorial
            </div>
            <div className="Link" onClick={() => scrollToSection(".Section3")}>
              Stats
            </div>
            <div className="Link" onClick={() => scrollToSection(".Section4")}>
              FAQ
            </div>
          </div>
          <div className="Admin">
            <div className="Link" onClick={() => privacyButtonClick()}>
              Privacy
            </div>
            <div className="Link" onClick={() => termsButtonClick()}>
              {" "}
              Terms
            </div>
            <div className="Social">
              <a href="https://twitter.com/validator_com">
                <FaTwitter />
              </a>
              <a href="https://discord.gg/eCJSb8PspY">
                <FaDiscord />
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* PRIVACY AND TERMS OVERLAYS */}

      {isPrivacyOverlayVisible && (
        <div className="overlay">
          <div className="overlay-content">
            <div className="CloseOverlay" onClick={privacyCloseClick}>
              X
            </div>

            <h1> Privacy Policy</h1>
            <p>
              This Privacy Policy discloses Validator.com practices and policies
              regarding the gathering and dissemination of personal information
              (the “Privacy Policy”) through this Web site, Validator.com (the
              “Website”). This Privacy Policy should be read in conjunction with
              our Terms and Conditions of Use (“Terms of Use”) which you can
              access by <a href="/terms">here</a>, and the Terms of Use shall
              govern where any inconsistency exists between this Privacy Policy
              and the Terms of Use. This Privacy Policy does not apply to sites,
              products, or services that are not owned or operated by us, or to
              information we may receive from other sources. We may change or
              update this Privacy Policy from time to time. Any changed or
              revised Privacy Policy shall be effective immediately after it is
              posted on this Web Site. If you do not agree to the terms of this
              Privacy Policy, you must discontinue using this Website.
            </p>

            <p>Information gathered by This Website</p>
            <p>
              {" "}
              When you visit this Website, we may collect the following types of
              information: personally identifiable information you knowingly
              provide, information we collect automatically, and non-personally
              identifiable information you knowingly provide.
            </p>

            <p>
              {" "}
              We may collect personally identifiable information that you
              knowingly provide to us. For example, we may ask you to provide
              personally identifiable contact information (such as name,
              address, e-mail address and telephone number). In some cases (for
              example, if you register with us (or sign-in on this Website), we
              may also ask you to provide other information such as a personal
              ID, password, and a security question and answer.
            </p>
            <p> </p>
            <p>
              {" "}
              In addition, as you (and other users) browse this Website, our web
              servers may automatically collect certain information about such
              visits (usually through the use of cookies, javascript tags, clear
              .gif files (also known as “web beacons”), and the analysis of http
              headers), such as the user’s IP address, the user’s geographic
              location, the user’s domain name, the user’s browser type, the
              user’s user agent, how many users visit this Website, which pages
              the user visits and for how long, the date and time the user
              accesses this Website, the Internet address of the site from which
              the user directly linked to this Website and the links on this
              Website on which the user clicks. In addition, we may collect
              certain search parameters relating to searches conducted by the
              user which are not entered by the user.
            </p>

            <p>
              {" "}
              To improve the services we can offer you, we may decide to expand
              this Web Site's capabilities for obtaining information about users
              in the future. We will update this Privacy Policy as our practices
              change to ensure that you are aware of developments in this area.
            </p>

            <p>
              If we collect such information, we may use it for a number of
              purposes, including:{" "}
            </p>

            <p>
              • To establish and maintain a commercial relationship with you and
              to provide you with products and services. Account information may
              be retained by us to enable our website to identify you, to allow
              you to set up and access your account (if applicable) and to
              change your account information and/or services. This information
              is used only for these purposes and is only retained in
              transaction records required for internal management and auditing
              purposes, unless you are informed otherwise or you provide your
              consent or we are required to disclose same pursuant to a court
              order or other legal process.
            </p>
            <p>
              •To understand your needs and preferences. We may maintain a
              record of the services you receive from us (if applicable), and we
              may ask you for additional information so that we can serve you
              better.
            </p>
            <p>
              •To provide you with information you have requested, and to keep
              you informed with respect to ongoing changes to our website. Your
              email address may be used to send you any newsletters and mailings
              if you have requested us to do so. You can withdraw your consent
              for such mailings at any time by contacting us as set out in such
              communications.
            </p>
            <p> </p>
            <p>
              •To develop, enhance or provide new services. We may from time to
              time conduct surveys and research, requesting input from our
              visitors to help us improve our website, products and services in
              an effort to serve you better. Your participation in such surveys
              is voluntary, and in some cases you may have the option to
              participate anonymously. However, your name, address, email and
              telephone number may be required to take advantage of bonuses or
              other benefits which are available to survey participants, in
              which case your contact information will only be used for
              notification and fulfillment purposes.
            </p>
            <p> </p>
            <p>
              •To manage and administer our business. The nature of the Internet
              is such that it passively and automatically collects certain
              information about a user’s traffic patterns, which may be linked
              to their Internet Protocol (IP) addresses. These are unique
              Internet “addresses” assigned to all Web users by their Internet
              Service Providers (ISP). Server logs record statistical
              information, such as visitors’ IP addresses, type of operating
              systems, time and duration of visit, web pages requested, and
              identify visitors by categories such as domains and browser types.
              These statistics are only used on an aggregate basis and will not
              contain any information that could identify you personally.
            </p>
            <p>
              •To meet legal, regulatory, security and processing requirements
              (such as in response to a court order), and otherwise as permitted
              or required by law. We may also use this personal information to
              contact you regarding any such legal, regulatory, security, or
              processing requirements, and may be required to disclose this
              information pursuant to our Terms of Service.
            </p>
            <p>
              If we intend to use personal information for purposes not
              identified to you or as set out above, these other purposes will
              be described to you at the time of collection or before using the
              information. We will not use your information or otherwise
              disclose it for any other purpose without your consent.
            </p>
            <p>Cookies</p>
            <p>
              Users also should be aware that non-personal information and data
              may be automatically collected through the standard operation of
              our Internet servers or through the use of “cookies”. Cookies are
              small text files containing a unique identification number that
              identifies your browser - but not you - to our servers each time
              you visit our website. Cookies are not pervasive or invasive
              programs that enter a user’s system and damage files. They simply
              tell us which pages of our website are visited and by how many
              visitors. Cookies cannot, by themselves, disclose the individual
              identity of any site user, and we never combine information
              gathered by a cookie with personally identifiable information like
              your name, telephone number or email address, without your
              consent.
            </p>
            <p>
              You should be aware that we cannot control the use of cookies or
              the resulting information by advertisers’ or third parties’ web
              sites that you may visit as a result of clicking on a link from
              our Website. If you do not want information collected through the
              use of cookies, there is a simple procedure in most browsers that
              allows the user to deny or accept the cookie feature.
            </p>
            <p>Third Parties’ Access to Information</p>
            <p>
              We not in the business of selling visitor information to others.
              Our visitor information is not available for sale to any outside
              entity. We may contract with third parties to fulfill certain
              functions on our behalf, such as providing marketing assistance,
              analyzing data, preparing and maintaining site content, and
              providing customer service. These agents may have access to
              visitor information only as required in order to help them perform
              their functions on our behalf, but they are not allowed to use
              that information for any other purpose and must keep such
              information confidential.
            </p>

            <p>
              Visitor and Website related information is a valuable business
              asset. If for any reason we transfer or divests ourselves of
              business assets, our visitor information will be transferred as
              one of those business assets.
            </p>
            <p> </p>

            <p>External Links</p>

            <p>
              Our Website may contain links or references to other web sites to
              which this Privacy Policy does not apply. These sites are not
              owned or controlled us and we not responsible for the collection,
              use and disclosure of personal information or the privacy
              practices of other organizations or other web sites to which our
              site may refer visitors or provide links for. When submitting
              personal information on such other websites, we encourage you to
              read the privacy policy of those sites.
            </p>
            <p> </p>
            <p>Opt-in and Opt-Out Option</p>
            <p>
              We may offer you the opportunity of opting in to receive email
              communications from us. Upon request, we will allow any user to
              “opt out” of further email and/or promotional contacts at any
              time. Also, upon a user’s request, we will use commercially
              reasonable efforts to functionally delete the user and his or her
              personal information from our database; however, it may be
              impossible to delete a user's entry without some residual
              information because of backups and records of deletions.{" "}
            </p>
            <p> </p>
            <p>Your Consent</p>
            <p>
              Consent to the collection, use and disclosure of personal
              information may be given in various ways. Consent can be express
              (e.g., orally, electronically or on a form you may sign describing
              the intended uses and disclosures of personal information) or
              implied (e.g., when you provide information necessary for a
              service you have requested, or in some circumstances where notice
              has been provided to you about our intentions with respect to your
              personal information and you have not withdrawn your consent for
              an identified purpose, such as by using an “opt out” option
              provided). Consent may be given by your authorized representative
              (such as a legal guardian or a person having a power of attorney).
              Generally, by providing us with personal information, we will
              assume that you consent to our collection, use and disclosure of
              such information for the purposes identified or described in this
              privacy policy, if applicable, or as otherwise described at the
              time of collection. You may withdraw your consent to our
              collection, use and disclosure of personal information at any
              time, subject to contractual and legal restrictions and reasonable
              notice, however, please note that if you withdraw your consent to
              certain uses of your personal information, we may no longer be
              able to provide certain of our products or services.
            </p>
            <p>
              We do not collect, use or disclose your personal information other
              than as described in this Privacy Policy without your consent,
              unless permitted or required by law. We may be required or
              permitted under statute or regulation to collect, use or disclose
              personal information without your consent, for example in response
              to a court order or subpoena, to comply with local or federal
              regulations, or to collect a debt owed to us.
            </p>
            <p> </p>
            <p>
              Your Data Protection Rights under the General Data Protection
              Regulation (GDPR)
            </p>
            <p>
              If you are an individual resident of the European Economic Area
              (EEA), you may have the following data protection rights:
            </p>
            <p>
              If you wish to access, correct, update, or request deletion of
              your personal information, you can do so at any time by contacting
              Us as set out below.
            </p>
            <p>
              In addition, you can object to the processing of your personal
              information, ask us to restrict the processing of your personal
              information, or request portability of your personal information.
              Again, you can exercise these rights by contacting Us as set out
              below.
            </p>
            <p>
              You have the right to opt-out of marketing communications we send
              you at any time. You can exercise this right by clicking on the
              "unsubscribe" or "opt-out" link in marketing emails we send you.
              To opt-out of other forms of marketing, please contact us as set
              out below.
            </p>
            <p>
              Similarly, if we have collected and processed your personal
              information with your consent, then you can withdraw your consent
              at any time. Withdrawing your consent will not affect the
              lawfulness of any processing we conducted prior to your
              withdrawal, nor will it affect the processing of your personal
              information conducted in reliance on lawful processing grounds
              other than consent.
            </p>
            <p>
              You have the right to complain to a data protection authority
              about our collection and use of your personal information. For
              more information, please contact your local data protection
              authority.
            </p>
            <p>
              We respond to all requests we receive from individuals wishing to
              exercise their data protection rights in accordance with
              applicable data protection laws. You may be required to identify
              yourself as a person to which the GDPR is applicable in order for
              us to extend GDPR-mandated privacy requirements to you, and we
              reserve our right to deny provision of our services to you if we
              in our sole discretion determine that we want to provide the
              services only to persons resident outside the European Economic
              Area (“EAA”). If you are a resident of the EEA and believe we
              maintain your personal data subject to the General Data Protection
              Regulation (GDPR), you may find out more information, including
              about the complaint procedure,{" "}
              <a href="https://edps.europa.eu/data-protection/our-role-supervisor/complaints_en">
                here
              </a>
              .
            </p>
          </div>
        </div>
      )}

      {isTermsOverlayVisible && (
        <div className="overlay">
          <div className="overlay-content">
            <div className="CloseOverlay" onClick={termsCloseClick}>
              X
            </div>

            <h1>Terms of service.</h1>
            <p>WEB SITE TERMS OF USE </p>
            <p>
              Validator.com (“Provider”) reserves the right to change these
              terms and conditions at any time, and you agree that each visit
              you make to Provider’s Validator.com Web Site (“Provider’s Web
              Site”) shall be subject to the current terms and conditions as
              published on our website at http://validator.com/.
            </p>
            <p> </p>
            <p>General Terms of Use</p>
            <p>
              By accessing Provider’s Web Site, you are agreeing to be bound by
              these Web Site Terms of Use and all applicable laws and
              regulations, and you agree that you are solely responsible for
              compliance with any applicable local laws. If you do not agree
              with any of these terms, do not use this site. Any claim relating
              to Provider’s Web Site shall be governed by the laws of Barbados.
              The materials contained in Provider’s Web Site are protected by
              applicable copyright and trade mark laws.
            </p>
            <p> </p>
            <p>No Endorsement of Third Party Content</p>
            <p>
              Provider’s Web Site may contain links to and/or frames of third
              party web sites or third party content that are not operated or
              maintained by Provider (“Third Party Sites”). Provider is not
              responsible for the content of those sites and cannot guarantee
              that those sites will not change without our knowledge. Product
              and service information on Third Party Sites are the sole
              responsibility of each individual vendor or operator of such Third
              Party Site. The inclusion of such links and frames in Provider’s
              Web Site does not imply endorsement of such sites or any content,
              information, material, products or services provided on such
              sites. You will need to make your own independent judgment
              regarding your interaction with these linked sites. Provider makes
              no representations whatsoever concerning; (a) the information,
              software or other material appearing on, or accessible through,
              any Third Party Site (including without limitation, any
              advertisement for products or services on any Third Party Site),
              (b) the performance or operation of any Third Party Site
              (including, without limitation, any transactions initiated or
              conducted through any Third Party Site, any taxes associated
              therewith and any use by third parties of user credit card
              information), (c) any products or services advertised or sold on
              or through any Third Party Site (including, without limitation,
              the quality, safety and legality of such products or services or
              the sale thereof), or (d) the sellers of any products or services
              advertised or sold on or through any Third Party Site. If you
              decide to access any of the Third Party Sites linked to Provider’s
              Web Site, you do so entirely at your own risk. If you are
              accessing a Third Party Site through a link on Provider’s Web
              Site, you are advised to read the terms of use and privacy policy
              of such Third Party Site before you use such Third Party Site.
            </p>
            <p> </p>
            <p>Disclaimer and Limitation of Liability</p>
            <p>
              THE MATERIALS AND INFORMATION CONTAINED ON PROVIDER’S WEB SITE ARE
              PROVIDED “AS IS”, WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR
              IMPLIED, INCLUDING WITHOUT LIMITATION ANY WARRANTY FOR
              INFORMATION, SERVICES, OR PRODUCTS PROVIDED THROUGH OR IN
              CONNECTION WITH PROVIDER’S WEB SITE AND ANY IMPLIED WARRANTIES OR
              CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
              EXPECTATION OF PRIVACY OR NON‑INFRINGEMENT. In no event shall
              Provider or its service providers, affiliates, associates,
              subsidiaries or partners be liable for any damages, including,
              without limitation, damages for loss of data or profit, or due to
              business interruption, arising out of the use of or inability to
              use the materials contained on Provider’s Web Site, even if
              Provider has been notified of the possibility of such damage. Some
              jurisdictions do not allow the exclusion of implied warranties, so
              the above exclusion may not apply to you. This disclaimer of
              liability applies to any damages or injury caused by any failure
              of performance, error, omission, interruption, deletion, defect,
              delay in operation or transmission, computer virus, communication
              line failure, theft or destruction or unauthorized access to,
              alteration of, or use of record, whether for breach of contract,
              tortious behavior, negligence, or under any other cause of action.
            </p>
            <p> </p>
            <p>Limited License</p>
            <p>
              Provider hereby grants you a limited license to view on your
              computer any content made available on Provider’s Web Site for
              which a fee is not charged, for non-commercial, personal, or
              educational purposes only. No other use is permitted.
            </p>
            <p>
              Without limiting the generality of the foregoing you may not make
              any commercial use of such content or copy or host such content on
              your or any other person’s web site or FTP server. Nothing
              contained in this limited license shall be deemed as conferring
              any right in any copyright, trade mark, trade name, or other
              proprietary right of Provider or any other party who owns or has
              proprietary rights to the content, information and materials
              provided on Provider’s Web Site.
            </p>
            <p> </p>
            <p>Preservation of Intellectual Property Rights</p>
            <p>
              All material on this site, including, but not limited to images,
              illustrations and multimedia materials, is protected by copyrights
              which are owned and controlled by Provider or by other parties
              that have licensed their material to Provider. Material from this
              Web Site or from any other web site owned, operated, licensed or
              controlled by Provider may not be copied, reproduced, republished,
              uploaded, posted, transmitted, or distributed in any way.
              Modification of the materials or use of the materials for any
              other purpose is a violation of the copyrights and other
              proprietary rights held by the respective Providers thereof. The
              use of any such material on any other web site, ftp server, or
              network environment is prohibited.
            </p>
            <p> </p>
            <p>Changes and Improvements</p>
            <p>
              Provider reserves the right, at its sole discretion, to change,
              modify, add or remove portions of these Terms of Use, at any time.
              It is your responsibility to check these Terms of Use periodically
              for changes. Your continued use of the Web Site following the
              posting of changes will mean that you accept and agree to the
              changes. As long as you comply with these Terms of Use, Provider
              grants you a personal, non-exclusive, non-transferable, limited
              privilege to enter and use the Site.
            </p>
            <p> </p>
            <p>Privacy Policy</p>
            <p>
              Provider’s Privacy Policy applies to use of this Site, and its
              terms are made a part of these Terms of Use by this reference. To
              view Provider’s Privacy Policy, click <a href="/pagepp">here</a>.
              Additionally, by using the Web Site, you acknowledge and agree
              that Internet transmissions are never completely private or
              secure.{" "}
            </p>
            <p> </p>
            <p>Jurisdictional Issues</p>
            <p>
              Due to the nature of the Internet, it is not possible for Provider
              to restrict access to its Web Site only to the jurisdiction where
              Provider is located. Some or all of the product and service
              advertisements which are provided by third parties on this Web
              Site may not be eligible for solicitation in your jurisdiction. If
              you are accessing this Web Site from such a jurisdiction, you
              should not consider anything on this site as an advertisement or
              as a solicitation to the public to purchase any product or service
              from any advertisers that is advertising their good or service on
              or the Provider’s Web Site. This site is for use only by persons
              residing in jurisdictions where such products and services may
              legally advertise.
            </p>
            <p> </p>
            <p>
              Unless otherwise specified, the materials contained in Provider’s
              Web Site are presented solely for information purposes in Barbados.
              Provider makes no representation that any of the materials
              contained in Provider’s Web Site are appropriate or available for
              use in other locations. Those who choose to access this Web Site
              from other locations do so on their own initiative and are
              responsible for compliance with local laws, if and to the extent
              local laws are applicable.
            </p>
            <p> </p>
            <p>
              This agreement shall be governed by and construed in accordance
              with the laws of Barbados and the parties hereby irrevocably and
              exclusively attorn to the jurisdiction of the courts of Barbados.{" "}
            </p>
            <p> </p>
            <p>Miscellaneous</p>
            <p>
              If any of the provisions of these Terms of Use are held by a court
              or other tribunal of competent jurisdiction to be void or
              unenforceable, such provisions shall be limited or eliminated to
              the minimum extent necessary and replaced with a valid provision
              that best embodies the intent of these Terms of Use, so that these
              Terms of Use shall remain in full force and effect. These Terms of
              Use constitute the entire agreement between you and Provider with
              regard to your use of the Web Site, and any and all other written
              or oral agreements or understandings previously existing between
              you and Provider with respect to such use are hereby superseded
              and cancelled. These Terms of Use shall not be interpreted or
              construed to confer any rights or remedies on any third parties.
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
